<template>
    <pagination title="Documents" :entities="documents" header-colour-class="bg-transparent">
        <template v-slot:headers>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6">Name
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Expiry Date</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Front or Back</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Reminder Limit</th>

        </template>
        <template v-slot:items>
            <tr v-for="document in documents" @click="openDetails(document)" class="cursor-pointer">
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6">
                    {{document.entity.properties.get('name')}}
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">
                    {{document.entity.properties.get('expiry_date')}}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm">
                    {{document.entity.properties.get('front_or_back')}}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm">
                    {{document.entity.properties.get('reminder_limit')}}
                </td>
            </tr>
        </template>
    </pagination>
</template>
<script>
import Pagination from "@/v3/components/pagination/Pagination.vue";
import axios from "axios";
import {mapGetters} from "vuex";
import Loading from "@/components/Loading.vue";
import HrSinglePersonPagination from "@/v3/components/pagination/HrSinglePersonPagination.vue";

export default {
  name: 'HrDocumentPagination',
    data(){
      return {
          userDetails: null,
          singleUserDetailsOpen: false
      }
    },
    components: {HrSinglePersonPagination, Loading, Pagination},
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser",
        }),
    },
  props: {
    documents: {}
  },
    methods:{
      openDetails(entity){
          this.$emit('setLoading');
          this.userDetails = false;
          this.getDetails(entity);
      },
        getDetails(entity){
          this.$emit('setDetails', entity);
        }
    }
}
</script>
