<template>
        <main-template @slotLoaded="loadDocuments">
            <template v-slot:headerLottieDark>
                <dotlottie-player :src="getDarkLogo()"
                                  background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
            </template>
            <template v-slot:headerLottieLight>
                <dotlottie-player :src="getLightLogo()"
                                  background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
            </template>
            <template v-slot:header>Base</template>
            <template v-slot="slotProps">
            <fetch-siren-root :linkName='linkName' :link="link">

                <div slot-scope="{ response }" class="flex flex-grow overflow-hidden">

                        <div class="flex w-full overflow-hidden">
                            <div class="p-2 h-full">
                                <Hr-navigation :links="slotProps.links"/>
                            </div>
                            <div class="flex w-full h-full p-2 overflow-hidden">
                                <div class="rounded-xl bg-v3-white-100 w-full h-full margin-10 dark:bg-v3-gray-900 overflow-y-auto">
                                    <loading v-if="!response || !documents"></loading>
                                    <template v-else>
                                        <div class="p-2">
                                            <hr-document-pagination :documents="documents" @setDetails="setDetails" @setLoading="setLoading" />
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>

                    <template v-if="details">
                        <download-pdf v-if="details" :url="details.entity.properties.get('full_url')" @removeFromDisplay="details=null"></download-pdf>
                    </template>

                </div>
            </fetch-siren-root>
            </template>
        </main-template>
</template>

<script>
import DownloadPdf from "@/v3/components/modals/DownloadPdf.vue";
import FetchSirenRoot from '@/components/FetchSirenRoot';
import HrDocumentPagination from "@/v3/components/pagination/HrDocumentPagination.vue";
import HrNavigation from "@/v3/components/navigation/HrNavigation.vue";
import HrPeoplePagination from "@/v3/components/pagination/HrPeoplePagination.vue";
import HrSinglePersonPagination from "@/v3/components/pagination/HrSinglePersonPagination.vue";
import Loading from '@/components/Loading';
import MainTemplate from '@/v3/templates/MainTemplate';
import {mapGetters} from "vuex";
import Siren from "super-siren";

export default {
    components: {
        HrDocumentPagination,
        HrSinglePersonPagination,
        HrPeoplePagination,
        HrNavigation,
        FetchSirenRoot,
        Loading,
        MainTemplate,
        DownloadPdf

    },
    data() {
        return {
            slotProps: null,
            documents: null,
            details: null,
            singleDetailsOpen: false,
            loadingDetails: false
        }
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
        }),
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        },
    },
    mounted() {
        let follow = Siren.Helper.follow;
    },
    methods: {
        loadDocuments(slotProps){
            this.usersUrl = slotProps.links.filter(link => link.rels.includes('current-user')).first().href;
            Siren.get(this.usersUrl).then(res => {
                this.documents = res.body.entities.filter(entity =>entity.rels.contains('certificate'));
            }, this)
            return this.documents;
        },
        setDetails(details){
            this.details = details;
            this.loadingDetails = false;
        },
        setLoading(){
            this.details = null;
            this.loadingDetails = true;
        },
        getDarkLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=base_dark"
        },
        getLightLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=base_light"
        }
    }
}
</script>

